import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layout/AuthLayout'
Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: 'helper',
      component: AuthLayout,
      children: [
        {
          path: '/helper',
          name: 'helper',
          component: () => import(/* webpackChunkName: "demo" */ './views/Helper.vue')
        },{
          path: '/pageVisits',
          name: 'pageVisits',
          component: () => import(/* webpackChunkName: "demo" */ './views/Dashboard/PageVisitsTable.vue')
        }
      ]
    }
  ]
})
