<template>
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-7">
                <div class="card bg-secondary shadow border-0">
                    <div class="card-header bg-transparent pb-5">
                        <div class="text-muted text-center mt-2 mb-3">
                            <small>Choose Surah and Ayah, then copy through Glyph, PNG, Madani Font or Translation.</small>
                        </div>
                        <div class="btn-wrapper" id="surahWrapper" >
                            <div class="row">
                                <div class="col-sm-12 text-center">
                                    <base-dropdown v-model="selectedSurah" class="text-center">
                                        <base-button slot="title" outline type="primary" class="dropdown-toggle col-sm-12">Pick Surah {{ selectedSurah>0?selectedSurah:'' }}</base-button>
                                        <base-button class="dropdown-item " @click="changeSurah(surah.num)" v-for="surah in surahArray" :selected="selectedSurah === surah.num" :key="surah.num" :id="surah.num" :value="surah.num" >{{surah.num + ' (' + surah.surah + ')'}}</base-button>
                                    </base-dropdown>
                                    <base-dropdown v-model="selectedAyah" class="text-center">
                                        <base-button slot="title" outline type="primary" class="dropdown-toggle col-sm-12">Pick Ayah {{selectedAyah>0?selectedAyah:''}}</base-button>
                                        <base-button name="aya" class="dropdown-item " @click="updateImage(aya)" v-for="aya in Ayahs" :key="aya" :selected="selectedAyah === aya" :id="aya" :value="aya">{{aya}}</base-button>
                                    </base-dropdown>
                                    <base-dropdown v-model="selectedAyah" class="text-center">
                                        <base-button slot="title" outline type="primary" class="dropdown-toggle col-sm-12">Translation ({{selectedTranslationLang}})</base-button>
                                        <base-button name="translate" class="dropdown-item " @click="updateTranslationID(language.id)" v-for="language in translations" :key="language.id" :selected="selectedTranslationID === language.id" :id="language.id" :value="language.id">{{language.language_name + ' (' + language.author_name + ')'}}</base-button>
                                    </base-dropdown>
                                    <base-button outline type="primary" @click="reset" class="btn btn-neutral text-center"><i class="fa fa-sync-alt"></i></base-button>
                                </div>
                            </div>
                            <br>
                            <div class="row">       
                                <div class="col-sm-12 text-center">
                                    <base-dropdown v-model="selectedSurah" class="text-center">
                                        <base-button slot="title" outline type="primary">{{ '(' + glyphOrientation.split("")[0] + ')'}} <i class="fas fa-cog"></i></base-button>
                                        <base-button class="dropdown-item" @click="changeGlyphOrientation(orientation)" v-for="orientation in orientations" :selected="glyphOrientation === orientation" :key="orientation" :id="orientation" :value="orientation" >{{orientation + ' Glyph'}}</base-button>
                                    </base-dropdown>
                                    <base-button outline :disabled="selectedAyah <= 0" type="primary" @click="copyGlyph" class="btn btn-neutral text-center">Glyph</base-button>
                                    <base-button outline :disabled="selectedAyah <= 0" type="primary" @click="copyGlyphV2" class="btn btn-neutral text-center">Glyph V2</base-button>
                                    <base-button outline :disabled="selectedAyah <= 0" type="primary" @click="copyFont" class="btn btn-neutral text-center">Madani Font</base-button>
                                    <base-button outline type="primary" @click="copyImage" class="btn btn-neutral text-center">Image</base-button>
                                    <base-button outline :disabled="selectedAyah <= 0" type="primary" @click="copyEnglish" class="btn btn-neutral text-center">English</base-button>
                                    <a download :href="require('@/data/' + selectedPNG)">
                                       <base-button outline type="primary" class="btn btn-neutral text-center"><i class="fa fa-download"></i></base-button>
                                        <!--<base-button outline type="primary" class="btn btn-neutral text-center"> Download</base-button>-->
                                    </a>
                                </div>
                            </div>
                            <br>
                            <div class="row" >
                                <div class="col alertbox flex-container" >
                                    <base-alert type="primary" class="col-sm-12 text-center alertstyle" v-if="copied">
                                        <strong>Copied {{copiedType}}!</strong> You can now paste in your application!
                                    </base-alert>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <base-button class="btn btn-primary btn-icon float-left " size="sm" :disabled="(selectedAyah == 0 || selectedAyah >= maxAyahOfSelected)? true:false" @click="nextAyah" type="primary">Next Ayah</base-button>
                                </div>
                                
                                <div class="col">                         
                                    <base-button class="btn btn-primary btn-icon float-right" size="sm" :disabled="selectedAyah <= 1? true:false" @click="previousAyah" type="primary">Prev Ayah</base-button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body px-lg-5 py-lg-5" id="bodyBox">

                        <div class="row">
                            <div id="extended-img" ref="wrapper" class="text-center col-sm-10"> 
                                <img :src="require('@/data/' + selectedPNG)" class="img-responsive oversize" />
                            </div>
                            
                            <div id="extended-img" ref="wrapper" class="col-sm-2 float-right vertical">
                                <base-button outline type="primary" @click="copyMain" class="mt-3 col-sm-12 btn btn-neutral text-center">Copy</base-button>
                            </div>
                        </div>

                        <br>
                        <br>

                        <div class="row" v-show="selectedAyah > 0">
                            <div id="extended-img" ref="wrapper" class="col-sm-10" > 
                                <h2 >{{translation}}</h2>
                            </div>
                            <div id="extended-img" ref="wrapper" class="col-sm-2 float-right vertical" > 
                                <base-button outline type="primary" v-show="selectedAyah > 0" @click="copyTranslation" class="mt-2 col-sm-12 btn btn-neutral text-center">Copy</base-button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    import surahs from '../data/surahList.json';
    //import translations from '../data/translations.json';

    export default {
        name: 'Helper',
        components:{
            
        },
        props: [
           
        ],
        data() {
            return {
                selectedSurah: 0,
                Ayahs: [],
                selectedAyah: 0,
                maxAyahOfSelected: 0,
                surahArray: surahs,
                //ayahList: ayahs,
                translations: [],
                pageNumber:'',
                selectedPNG:'0.png',
                copied: false,
                copiedType:'',
                translation:'',
                glyphOrientation: 'Forward',
                orientations: ['Forward', 'Reverse'],
                selectedTranslationID: 131,
                selectedTranslationLang: 'English',
                model: {
                    email: '',
                    password: ''
                },
                settings: {
                    suppressScrollY: false,
                    suppressScrollX: false,
                    wheelPropagation: false
                }
            }
        },
        mounted(){
            if (localStorage.selectedAyah != undefined){
                this.selectedTranslationID  = parseInt(localStorage.selectedTranslationID);
                this.selectedTranslationLang = localStorage.selectedTranslationLang;
                this.selectedAyah = parseInt(localStorage.selectedAyah);
                this.selectedSurah = parseInt(localStorage.selectedSurah);
                this.glyphOrientation = localStorage.glyphOrientation;
                //this.updateImage(this.selectedAyah);
                this.onMountSurahAyahUpdate(this.selectedSurah, this.selectedAyah);
            }

            var translationsURL = 'https://api.quran.com/api/v4/resources/translations';
            this.axios.get(translationsURL)
            .then(response => {
                var transTemp = response.data.translations;
                this.translations = transTemp.sort((a, b)=>{ 
                    return (a.language_name.toLowerCase() < b.language_name.toLowerCase()) ? -1 : (a.language_name.toLowerCase() > b.language_name.toLowerCase()) ? 1 : 0; 
                });
            }).catch(error => {
                console.log(error);
                this.translations = 'ERROR! Please contact us!';
            });
        },
        updated() {
            if (this.selectedAyah != 0 && this.selectedSurah != 0){
                localStorage.selectedTranslationID = this.selectedTranslationID;
                localStorage.selectedTranslationLang = this.selectedTranslationLang;
                localStorage.selectedAyah = this.selectedAyah;
                localStorage.selectedSurah = this.selectedSurah;
                localStorage.glyphOrientation = this.glyphOrientation;
            }
        },
        methods:{
            reset(){
                localStorage.removeItem('selectedTranslationID');
                localStorage.removeItem('selectedTranslationLang');
                localStorage.removeItem('selectedAyah');
                localStorage.removeItem('selectedSurah');
                localStorage.removeItem('glyphOrientation');
                location.reload();
            },
            copyImage(){
                //Make the container Div contenteditable
                var img = document.getElementById("extended-img");
                img.contentEditable = true;
                img.setAttribute("src", '../data/' + this.selectedPNG)
                if (document.body.createTextRange) {
                    var range = document.body.createTextRange();
                    range.moveToElementText(img);
                    range.setSelectionRange(0, 99999);
                    range.select();
                } else if (window.getSelection) {
                    var selection = window.getSelection();
                    range = document.createRange();
                    range.selectNodeContents(img);
                    selection.removeAllRanges();
                    selection.addRange(range);
                }
                document.execCommand('copy');
                window.getSelection().removeAllRanges();
                img.contentEditable = false;
                this.copied = true;
                setTimeout(() => this.copied = false, 1000)
                this.copiedType = 'Image';
            },
            copyGlyph(){
                if(this.selectedAyah > 0){
                    var baseURL = `https://api.qurancliphelper.com/glyph/${this.selectedSurah}/${this.selectedAyah}`
                    this.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
                    
                    var glyph;
                    this.axios.get(baseURL)
                    .then(response => {
                        glyph = (this.glyphOrientation === 'Reverse')? response.data[0].split("").reverse().join("") :response.data[0];
                        console.log(glyph);
                        this.pageNumber = response.data[1];
                        var codeDiv2 = document.createElement('input');
                        codeDiv2.setAttribute("value", glyph);
                        codeDiv2.style.display = "hidden";
                        document.body.appendChild(codeDiv2);
                        codeDiv2.select();
                        codeDiv2.setSelectionRange(0, 99999);
                        document.execCommand('copy');
                        document.body.removeChild(codeDiv2);

                        // Glyph copy
                        this.copied = true;
                        setTimeout(() => this.copied = false, 3500)
                        this.copiedType = 'Glyph (QCF_' +  this.pageNumber +')';
                    }).catch(error => {
                        console.log(error);
                        
                        // Copy error
                        this.copied = true;
                        setTimeout(() => this.copied = false, 2000)
                        this.copiedType = 'ERROR! Please contact us!';
                    })    
                }
            },
            copyGlyphV2(){
                if(this.selectedAyah > 0){
                    var baseURL = `https://api.quran.com/api/v4/verses/by_key/${this.selectedSurah}:${this.selectedAyah}?language=en&words=false&fields=code_v2,page_number`
                    this.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
                    
                    var glyph;
                    this.axios.get(baseURL)
                    .then(response => {
                        glyph = (this.glyphOrientation === 'Reverse')? response.data.verse.code_v2.split("").reverse().join("") :response.data.verse.code_v2;
                        console.log(glyph);
                        this.pageNumber = response.data.verse.page_number;
                        var codeDiv2 = document.createElement('input');
                        codeDiv2.setAttribute("value", glyph);
                        codeDiv2.style.display = "hidden";
                        document.body.appendChild(codeDiv2);
                        codeDiv2.select();
                        codeDiv2.setSelectionRange(0, 99999);
                        document.execCommand('copy');
                        document.body.removeChild(codeDiv2);

                        // Glyph copy
                        this.copied = true;
                        setTimeout(() => this.copied = false, 3500)
                        this.copiedType = 'Glyph (QCF_' +  this.pageNumber +')';
                    }).catch(error => {
                        console.log(error);
                        
                        // Copy error
                        this.copied = true;
                        setTimeout(() => this.copied = false, 2000)
                        this.copiedType = 'ERROR! Please contact us!';
                    })    
                }

            },
            changeGlyphOrientation(orient){
                if (orient === 'Forward'){
                    this.glyphOrientation = 'Forward';
                } else if (orient === 'Reverse'){
                    this.glyphOrientation = 'Reverse';
                }
            },
            copyFont(){
                if(this.selectedAyah > 0){
                    var baseURL = `https://api.qurancliphelper.com/arabic/${this.selectedSurah}/${this.selectedAyah}`
                    this.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
                    
                    var arabic;
                    this.axios.get(baseURL)
                    .then(response => {
                        //console.log(response.data)
                        arabic = response.data;
                        var codeDiv2 = document.createElement('input');
                        codeDiv2.setAttribute("value", arabic);
                        codeDiv2.style.display = "hidden";
                        document.body.appendChild(codeDiv2);
                        codeDiv2.select();
                        codeDiv2.setSelectionRange(0, 99999);
                        document.execCommand('copy');
                        document.body.removeChild(codeDiv2);

                        // Arabic Copied
                        this.copied = true;
                        setTimeout(() => this.copied = false, 1000)
                        this.copiedType = 'Madani Font Copied!';
                    }).catch(error => {
                        console.log(error);
                        
                        // Copy error
                        this.copied = true;
                        setTimeout(() => this.copied = false, 2000)
                        this.copiedType = 'ERROR! Please contact us!';
                    })    
                }
            },
            copyEnglish(){
                if(this.selectedAyah > 0){
                    var baseURL = `https://api.qurancliphelper.com/translations/101/${this.selectedSurah}/${this.selectedAyah}`
                    this.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
                    
                    var translationText;
                    this.axios.get(baseURL)
                    .then(response => {
                        //console.log(response.data)
                        translationText = response.data;
                        var codeDiv2 = document.createElement('input');
                        codeDiv2.setAttribute("value", translationText);
                        codeDiv2.style.display = "hidden";
                        document.body.appendChild(codeDiv2);
                        codeDiv2.select();
                        codeDiv2.setSelectionRange(0, 99999);
                        document.execCommand('copy');
                        document.body.removeChild(codeDiv2);

                        // Translation Copied
                        this.copied = true;
                        setTimeout(() => this.copied = false, 1000)
                        this.copiedType = 'English Translation Copied!';
                    }).catch(error => {
                        console.log(error);
                        
                        // Copy error
                        this.copied = true;
                        setTimeout(() => this.copied = false, 2000)
                        this.copiedType = 'ERROR! Please contact us!';
                    })
                }
            },
            copyTranslation(){
                var translationText = "";
                if(this.selectedAyah > 0){
                    translationText = this.translation;
                    var codeDiv2 = document.createElement('input');
                    codeDiv2.setAttribute("value", translationText);
                    codeDiv2.style.display = "hidden";
                    document.body.appendChild(codeDiv2);
                    codeDiv2.select();
                    codeDiv2.setSelectionRange(0, 99999);
                    document.execCommand('copy');
                    document.body.removeChild(codeDiv2);

                    // Translation Copied
                    this.copied = true;
                    setTimeout(() => this.copied = false, 1000)
                    this.copiedType = this.selectedTranslationLang + ' Translation';                
                }
            },
            changeSurah(surah){
                this.selectedAyah = 0;
                var ayahTemp = [];
                this.selectedSurah = surah;
                this.maxAyahOfSelected = surahs[surah -1].ayas;
                for (var j = 0; j < surahs[surah-1].ayas; j++)
                    ayahTemp.push(j+1);
                
                var x = document.getElementById("bodyBox");
                x.style.backgroundColor = "#172b4d"
                x.style.borderRadius = "0.375rem";
                this.Ayahs = ayahTemp;
                this.selectedPNG = 'title/'+ surah + '.png'
            },
            onMountSurahAyahUpdate(surah, ayah){
                var ayahTemp = [];
                this.selectedSurah = surah;
                this.maxAyahOfSelected = surahs[surah -1].ayas;
                for (var j = 0; j < surahs[surah-1].ayas; j++)
                    ayahTemp.push(j+1);
                this.Ayahs = ayahTemp;
                this.updateImage(ayah);
            },
            updateImage(ayah){
                var x = document.getElementById("bodyBox");
                x.classList.add("card-body");
                x.style.backgroundColor = "#f7fafc"
                x.style.borderRadius = "0.375rem";
                this.selectedAyah = ayah;
                this.selectedPNG = 'surah/'+ this.selectedSurah + '_' + ayah +'.png'
                this.updateTranslation();
            },
            nextAyah(){
                this.selectedAyah = this.selectedAyah + 1;
                this.selectedPNG = 'surah/'+ this.selectedSurah + '_' + this.selectedAyah +'.png'
                this.updateTranslation();
            },
            previousAyah(){
                this.selectedAyah = this.selectedAyah - 1;
                this.selectedPNG = 'surah/'+ this.selectedSurah + '_' + this.selectedAyah +'.png'
                this.updateTranslation();
            },
            updateTranslationID(id){
                this.selectedTranslationID = id;
                for (var a = 0; a < this.translations.length; a++){
                    if (this.translations[a].id == id){
                        this.selectedTranslationLang = this.translations[a].language_name;
                    }
                }
                this.updateTranslation();
            },
            updateTranslation(){
                if (this.selectedSurah == 0 || this.selectedAyah == 0) return;
                if (this.selectedTranslationID == 131){
                     var baseURL = `https://api.qurancliphelper.com/translations/101/${this.selectedSurah}/${this.selectedAyah}`
                    //var baseURL = `http://localhost:3000/translations/${this.selectedTranslationID}/${this.selectedSurah}/${this.selectedAyah}`
                    
                    this.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
                    this.axios.get(baseURL)
                    .then(response => {
                        this.translation = response.data;
                    }).catch(error => {
                        console.log(error);
                        this.translation = "CANNOT CONNECT TO NETWORK!"                        
                    })
                } else {
                    var translationsURL = `https://api.quran.com/api/v4/verses/by_key/${this.selectedSurah}:${this.selectedAyah}?language=en&words=false&translations=${this.selectedTranslationID}`;
                    this.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
                    this.axios.get(translationsURL)
                    .then(response => {
                        this.translation = response.data.verse.translations[0].text;
                    }).catch(error => {
                        console.log(error);
                        this.translation = "CANNOT CONNECT TO NETWORK!";
                    }); 
                } 
            },
            copyMain(){
                if(this.selectedAyah > 0){
                    this.copyFont();
                } else {
                    this.copyImage();
                }
            }            
        }
    }
</script>

<style >
    .dropdown-menu {
        overflow-y: scroll;
        max-height: 200px;
    }
    /* width */
    ::-webkit-scrollbar {
    width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
    background:#2d4f8a;
    box-shadow: inset 0 0 5px #172b4d;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #172b4d;
    border-radius: 10px;
    }
    .oversize {
    width: 100%;
    height: auto;
    }
    .vertical {
    display: flex;
    align-items: center;
    }
</style>
