<template>
    <div class="main-content bg-default">
        <!-- Navbar -->
        <base-nav class="navbar-top navbar-horizontal navbar-dark"
                  containerClasses="px-4 container"
                  expand>
            <router-link slot="brand" class="navbar-brand" to="/">
                <img src=""/>
            </router-link>

            <template v-slot="{closeMenu}">
                <!-- Collapse header -->
                <div class="navbar-collapse-header d-md-none">
                    <div class="row">
                        <div class="col-6 collapse-brand">
                            <router-link to="/">
                                <img src="img/brand/green.png">
                            </router-link>
                        </div>
                        <div class="col-6 collapse-close">
                            <button type="button"
                                    @click="closeMenu"
                                    class="navbar-toggler"
                                    aria-label="Toggle sidenav">
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Navbar items -->
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <router-link class="nav-link nav-link-icon" @click.native="clickModal0" to="/">
                            <i class="ni ni-planet"></i>
                            <span class="nav-link-inner--text">Contact Us</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link nav-link-icon" @click.native="clickModal1" to="/">
                            <i class="ni ni-bullet-list-67"></i>
                            <span class="nav-link-inner--text">FAQ</span>
                        </router-link>
                    </li>
                </ul>
            </template>
        </base-nav>
        <!-- Header -->
        <div class="header bg-gradient-success py-7 py-lg-10">
            <div class="container">
                <div class="header-body text-center mb-5">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-md-10">
                            <h1 class="text-white">Quran-Clip Helper</h1>
                            <p class="text-lead text-white">Asalam Alykom! The purpose of this site is to make it easier for people making Quran clips to use beautiful Quran fonts. This is a free site so please keep the developers in your duaa!</p>
                            <br>
                            <div class="text-center float-center">         
                                <a href="https://youtu.be/C_2RKeIyY-U" class="nav-link" target="_blank">Tutorial: How to use this site!</a>
                                <a href="https://youtu.be/C_2RKeIyY-U" class="nav-link" target="_blank">( كيفية الاستخدام )</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="separator separator-bottom separator-skew zindex-100">
                <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
                     xmlns="http://www.w3.org/2000/svg">
                    <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div>
        </div>
        <!-- Page content -->
        <div class="container mt--8 pb-8">
            
            <Helper mode="out-in" origin="center top">
                <router-view></router-view>
            </Helper>
            
        </div>
        <modal :show.sync="modals.modal0" :modal="modals">
            <h2 slot="header" class="modal-title" id="modal-title-default">Contact Info</h2>

            <p>> moazelsayedquran@gmail.com</p>
            <p>> facebook.com/moazquran</p>
            <p>> instagram.com/moazquran</p>

            <template slot="footer">
                <base-button outline type="primary" class="btn btn-neutral btn-icon px-lg-3 text-center" @click="modals.modal0 = false">Close
                </base-button>
            </template>
        </modal>
        <modal :show.sync="modals.modal1" :modal="modals">
            <h2 slot="header" class="modal-title" id="modal-title-default">Frequently Asked Questions</h2>

            <p>Note: Translations are pulled straight from an API, some translations might not be fully reviewed. If any mistakes are found please contact us immediately</p>

            <p>I found a bug, where do I report it?<br>
            <small>Please email me right away or send me a message on facebook! moazelsayedquran@gmail.com</small></p>

            <p>What default english translation is being used?<br>
            <small>The translation being used is <a href="https://theclearquran.org/"> The Clear Qur'an by Dr. Mustafa Khatab</a></small></p>

            <p>How can I use Glyphs?<br>
            <small>Please follow the <a href="https://youtu.be/C_2RKeIyY-U">instructional video here</a></small></p>
            
            <p>Where can I download Filmora?<br>
            <small>On their official <a href="https://filmora.wondershare.com/download-filmora9-windows.html">site here </a></small></p>
            
            <p>How do Glyphs differ from png image?<br>
            <small>Glyphs are symbol representations for actual fonts. These fonts are from the King Fahad Quran Complex.
                This font family needs to be downloaded and installed on your computer before you are able to use them on filmora.
                <a href="https://drive.google.com/file/d/1_LUv-Ol8a1ItJN7c-TtpSwjdchaPRc9g/view?usp=sharing">You can download the font family here </a>
            </small></p>

            <p>What arabic font is it using?<br>
            <small>Font Madani</small></p>

            <p>Can I use your API?<br>
            <small>Sure thing! just please don't bog it down --> api.qurancliphelper.com</small></p>
            
            <p>How can I request additional features to this site?<br>
            <small>Please contact me (moazelsayedquran@gmail.com) and i'll see if i'm able to add it inshaAllah.</small></p>
            
            
            <template slot="footer">
                <base-button outline type="primary" class="btn btn-neutral btn-icon px-lg-3 text-center" @click="modals.modal1 = false">Close
                </base-button>
            </template>
        </modal>
        <footer class="py-6">
            <div class="container">

            </div>
        </footer>
    </div>
</template>
<script>
  //import { SlideYUpTransition } from 'vue2-transitions'
  import Helper from '../views/Helper.vue'

  export default {
    name: 'auth-layout',
    components: {
      //SlideYUpTransition,
      Helper,
    },
    data() {
      return {
        year: new Date().getFullYear(),
        showMenu: false,
        modals:{
            modal0: false,
            modal1: false
        }
      }
    },
    methods:{
        clickModal0(e){
            e.preventDefault();
            this.modals.modal0 = true;
        },
        clickModal1(e){
            e.preventDefault();
            this.modals.modal1 = true;
        }
    }
  }
</script>
<style>

</style>
